import React from "react"
import { graphql } from "gatsby"
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import Layout from "../components/layout.en"
import SEO from "../components/seo"
import TitleBlock from "../components/blocks/TitleBlock"
import Img from "gatsby-image"
import HorizontalCardBlock from "../components/blocks/HorizontalCardBlog"
import Photos from "../components/general/Photos"
import ContactInfo from "../components/general/ContactInfo"
import Map from "../components/general/Map"
import BigHeader from "../components/header"

const IndexPage = ({ data }) => {
  React.useEffect(() => {
    document.body.classList.add("sections-page")
    document.body.classList.add("sidebar-collapse")
    document.documentElement.classList.remove("nav-open")
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    )
    var hrefId = href.substring(href.lastIndexOf("#") + 1)

    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
      })
    }

    return function cleanup() {
      document.body.classList.remove("sections-page")
      document.body.classList.remove("sidebar-collapse")
    }
  })

  return (
    <Layout>
      <SEO title="Homepage" lang="en" />
      <BigHeader
        title={`Comfortable apartments in the vicinity of the beach`}
        description={`Morska 5 is a new holiday resort situated in picturesque surroundings in the first line of seaside buildings, just 50 meters from the beach. Comfortable and well equipped rooms allow you to enjoy the comfort of your holiday.`}
        ctaText={`Book your stay`}
        ctaTo={`/en/#contact`}
      />

      <Container>
        <div className="section pb-0">
          <Row>
            <Col className="ml-auto mr-auto text-center title" md="6">
              <TitleBlock
                title={`A new place on the map of Niechorze`}
                description={`Comfortable interiors, modern building, excellent location. We have everything you need to enjoy all the charms of the Baltic resort.`}
                headingLevel="h2"
                extendedClasses="mb-0 color-sea font-weight-bold"
              />
            </Col>
          </Row>
          <div className="section-story-overview pb-0">
            <Row>
              <Col className="ml-auto mr-auto" md="4">
                <div className="image-container image-left">
                  <Img
                    fluid={data.story1.childImageSharp.fluid}
                    style={{ position: "asbolute" }}
                  />
                </div>
                <div className="text-under-image">
                  <h3
                    style={{
                      color: "#3D3D3F",
                      paddingTop: "30px",
                    }}
                    className="text-center text-md-left"
                  >
                    Rooms and apartments for rent - Morska 5
                  </h3>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="4">
                <div className="image-container image-right">
                  <Img
                    fluid={data.story2.childImageSharp.fluid}
                    style={{ position: "asbolute" }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <p
                  style={{
                    textAlign: "justify",
                  }}
                >
                  Morska 5 is a modern leisure facility located in the immediate
                  vicinity of both clean, wide and sandy beaches, as well as
                  shops, restaurants and bars.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                  }}
                >
                  The surrounding buildings and squares are maintained in a
                  traditional seaside climate and surrounded by the natural
                  beauty of trees and dunes. The building is equipped with an
                  elevator and garage places, and each apartment has its own
                  balcony.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                  }}
                >
                  Upper floors balconies offer a outstanding view across the
                  Baltic Sea and the lighthouse. A well-kept area around the
                  facility provides resting points for our guests, a barbecue
                  stand with benches and comfortable seats.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                  }}
                >
                  We have also taken care of the youngest who can spend time on
                  a safe playground or in the playroom, where they can find
                  building bricks and toys as well as a game console.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      <Container>
        <div className="section pt-0 pb-0">
          <div className="section-story-overview pb-0">
            <Row>
              <Col className="ml-auto" md="6">
                <div className="image-container image-left">
                  <Img
                    fluid={data.story4.childImageSharp.fluid}
                    style={{ position: "asbolute" }}
                  />
                  <p
                    className="blockquote border-sea color-sea"
                    style={{ fontWeight: 500 }}
                  >
                    "Every year Niechorze attracts lovers of blissful laziness
                    and sunbathing, as well water sports, hiking and biking
                    fans. " <br></br>
                    <br />
                  </p>
                </div>
                <div className="image-container">
                  <Img
                    fluid={data.story3.childImageSharp.fluid}
                    style={{ position: "asbolute" }}
                  />
                </div>
              </Col>
              <Col className="mr-auto" md="4">
                <div className="image-container image-right">
                  <Img
                    fluid={data.story5.childImageSharp.fluid}
                    style={{ position: "asbolute" }}
                  />
                </div>
                <h3 className="text-center text-md-left">
                  Niechorze - surprises with its diversity
                </h3>
                <p className="text-justify text-md-left">
                  Niechorze is a former fishing village, which is now one of the
                  fastest growing tourist destinations by the Baltic Sea. The
                  village in Rewal Commune (administrative district), with it
                  popoulation of nearly 1000, is famous for its beautiful
                  location and lighthouse. The western part of the Niechorze
                  rises on a 22-metre cliff with wonderful views. The eastern
                  part of the village is a sandy spit, which separates the sea
                  from the coastal Liwia Łuża lake.
                </p>
                <p className="text-justify text-md-left">
                  Only 50 meters away from the exit to the wide, clean beach,
                  Morska 5 is also very close to the main street, where you can
                  take advantage of the many restaurants, cafes and shops. Less
                  than a kilometre from our facility there is a symbol of
                  Niechorze - a lighthouse. It is also worthwhile to go to the
                  station of the historic narrow-gauge railway (250 m) or
                  indulge yourself with numerous charming walking routes.
                </p>
                <p className="text-justify text-md-left">
                  Bicycle enthusiasts will surely be delighted by the bicycle
                  trail running among forests, along the coastline from
                  Niechorze to Mrzeżyn or the picturesque bicycle trail around
                  Liwia Łuża lake and the surrounding ornithological reserve.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      <div className="section front__blog pt-0 pb-0" id="apartments">
        <div className="section-story-overview cd-section pt-0 pb-0">
          <div className="blogs-3">
            <Container className="mb-4">
              <Row>
                <Col className="ml-auto mr-auto text-center title mb-4" md="6">
                  <TitleBlock
                    title={`Perfectly equipped rooms and apartments await you`}
                    description={`All our apartments have a kitchenette, bathroom, balcony and full equipment.`}
                    headingLevel="h2"
                    extendedClasses="mb-0 color-gold font-weight-bold"
                  />
                </Col>
              </Row>
            </Container>

            <Container className="apartments">
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <HorizontalCardBlock
                    title={`Two-person apartment`}
                    description={`Cosy, fully equipped apartment for two people with a kitchenette, bathroom and a balcony. See photos and equipment details.`}
                    link="/en/apartament-2-osobowy"
                    image={data.roomCovers.edges[0].node.childImageSharp.fluid}
                    ctaText={`See apartament`}
                  />

                  <HorizontalCardBlock
                    title={`Three-person apartment`}
                    description={`One room, comfortably equipped apartment for three people with a kitchenette, bathroom and a balcony. See photos and equipment details.`}
                    link="/en/apartament-3-osobowy"
                    image={data.roomCovers.edges[1].node.childImageSharp.fluid}
                    ctaText={`See apartament`}
                  />

                  <HorizontalCardBlock
                    title={`Three-person apartment (one bedroom apartment)`}
                    description={`A three-person, fully equipped apartment consisting of a living room with a sofa bed, a kitchenette, a separate bedroom with a wide bed, bathroom and a balcony. See photos and equipment details.`}
                    link="/en/apartament-3-osobowy-2-pokojowy"
                    image={data.roomCovers.edges[2].node.childImageSharp.fluid}
                    ctaText={`See apartament`}
                  />

                  <HorizontalCardBlock
                    title={`Four-person apartment (one bedroom apartment)`}
                    description={`Comfortable, one bedroom apartment for four people consists of a living room with a sofa bed, kitchenette, bedroom with two beds, bathroom and a comfortable balcony. See photos and equipment details.`}
                    link="/en/apartament-4-osobowy"
                    image={data.roomCovers.edges[3].node.childImageSharp.fluid}
                    ctaText={`See apartament`}
                  />

                  <HorizontalCardBlock
                    title={`Five-person apartment (one bedroom apartment)`}
                    description={`Comfortable, one bedroom apartment for five people consisting of a living room with a sofa bed for two, a kitchenette, a bedroom with two beds (a large double and single twin), a bathroom and a balcony. See photos and equipment details.`}
                    link="/en/apartament-5-osobowy"
                    image={data.roomCovers.edges[4].node.childImageSharp.fluid}
                    ctaText={`See apartament`}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <div className="section front__info pb-0">
        <div className="projects-4 pt-4 pb-4" data-background-color="gray">
          <Container fluid>
            <Row>
              <Col className="px-0 order-md-1" md="6">
                <Card className="card-fashion card-background">
                  <Img
                    fluid={data.bg3Front.childImageSharp.fluid}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <CardBody>
                    <CardFooter className="text-left">
                      <div className="stats">
                        <span>
                          <i className="now-ui-icons objects_globe" />
                          Rich cuisine offer
                        </span>
                      </div>
                    </CardFooter>
                    <CardTitle className="text-left" tag="div">
                      <h2>
                        Just a few minutes walk from restaurants and shops
                      </h2>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-0 order-md-2" md="6">
                <div className="card-container">
                  <Card className="card-fashion">
                    <CardTitle tag="div">
                      <h4>
                        For children a safe and varied playground and a playroom
                        with a game console and creative toys.
                      </h4>
                    </CardTitle>
                    <CardBody>
                      <CardFooter>
                        <div className="stats">
                          <span>
                            <i className="now-ui-icons education_paper" />
                            Lots of attractions for the youngest
                          </span>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                  <Card className="card-fashion card-background d-none d-md-block">
                    <Img
                      fluid={data.bg1Front.childImageSharp.fluid}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Card>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="px-0 order-md-3 order-4" md="6">
                <div className="card-container">
                  <Card className="card-fashion card-background d-none d-md-block">
                    <Img
                      fluid={data.bg4Front.childImageSharp.fluid}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Card>
                  <Card className="card-fashion arrow-left">
                    <CardTitle tag="div">
                      <h4>
                        On the premises of the object there are places to
                        barbecue, relax and spend time together.
                      </h4>
                    </CardTitle>
                    <CardBody>
                      <CardFooter>
                        <div className="stats">
                          <span>
                            <i className="now-ui-icons design_app" />
                            Exclusive to our guests
                          </span>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col className="px-0 order-md-4 order-3" md="6">
                <Card className="card-fashion card-background">
                  <Img
                    fluid={data.bg2Front.childImageSharp.fluid}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <Col md="10" className="mr-auto ml-auto">
        <div className="section" id="photos">
          <Photos
            title="See photos from Morska 5"
            description="We took care not only of the equipment of rooms, but also of the friendly and practical environment. See what Morska 5 offers its guests."
            photos={data.gallery}
          />
        </div>
      </Col>

      <Col md="7" className="ml-auto mr-auto">
        <div className="section" id="contact">
          <ContactInfo
            title={`Book your stay`}
            description={`If you are interested in making a reservation, please contact us by phone or e-mail. The condition for confirming the reservation is 30% advance payment of the total value of the stay.`}
            telTitle={`Click and call to us`}
            telDescription={`We answer your calls 7 days a week, from 8:00 to 22:00`}
            mailDescription={`Please provide the date, quantity and type of rooms as well as your contact number in the e-mail.`}
            mailTitle={`Click and write with us`}
          />
        </div>
      </Col>

      <div className="section contactWithMap pb-0">
        <Map
          description={`You can easily find us on Google Maps, in case of any problems with directions, please contact us.`}
          ctaText={`Show directions`}
          lang="en"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query imagesEn {
    story1: file(relativePath: { eq: "story-1.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    story2: file(relativePath: { eq: "story-2.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    story3: file(relativePath: { eq: "story-3.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    story4: file(relativePath: { eq: "story-4.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    story5: file(relativePath: { eq: "story-5.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    temporaryRoom: file(relativePath: { eq: "temporary-room.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg3Front: file(relativePath: { eq: "bg-3-front.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg1Front: file(relativePath: { eq: "bg-1-front.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg4Front: file(relativePath: { eq: "bg-4-front.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg2Front: file(relativePath: { eq: "bg-2-front.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gallery: allFile(
      filter: {
        relativeDirectory: { in: ["gallery/1", "gallery/2", "gallery/3"] }
      }
      sort: { fields: relativeDirectory }
    ) {
      edges {
        node {
          id
          childImageSharp {
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes_withWebp
            }
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    roomCovers: allFile(
      sort: { fields: name }
      filter: { relativeDirectory: { eq: "rooms/covers" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default IndexPage
